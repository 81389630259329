<template>
  <div>
    <h3 class="card-label w-100">
      <span class="text-muted">Order ID:</span> #{{ order.invoice_prefix
      }}{{ order.invoice_no }}
    </h3>
    <div class="d-flex" v-if="order">
      <div class="flex-fill pr-5">
        <div
          class="shadow rounded-sm shadow bg-white p-4 mb-4"
          v-for="(item, i) in order.products"
          :key="i"
        >
          <div class="d-flex">
            <div class="min-w-400px max-w-400px">
              <div class="p-5 border mb-4">
                <img v-if="item.image != undefined" :src="imagePath + item.image.image"
                      class="img-fluid order-image" />
              </div>
            </div>
            <div class="flex-fill pl-4">
              <div class="d-block pb-4">
                <div class="font-weight-bold pb-3 m-0">Frame Detail</div>
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col" class="py-1 bg-light">Product ID</th>
                      <th scope="col" class="py-1 bg-light">Size</th>
                      <th scope="col" class="py-1 bg-light">Color</th>
                      <!-- <th scope="col" class="py-1 bg-light">Frame Price</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="py-1">#{{ item.model }}</td>
                      <td class="py-1">Medium</td>
                      <td class="py-1">Brown</td>
                      <!-- <td class="py-1">${{ item.price }}</td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="d-flex justify-content-between">
                <div class="font-weight-bold p-0 m-0">Usage for Glasses</div>
                <div class="font-weight-bold text-black p-0 m-0">
                  {{ item.glass_usage_name }}
                </div>
              </div>
              <hr class="my-1" />
              <div
                class="d-flex justify-content-between align-items-center"
                v-b-toggle="`prescription-${item.id}`"
                v-if="
                  item.prescription_type === 'text' || item.prescription_type === 'file'
                "
              >
                <div class="font-weight-bold pb-1 p-0 m-0">Priscription</div>
                <i class="las la-angle-down"></i>
              </div>
              <b-collapse
                :id="`prescription-${item.id}`"
                v-if="item.prescription_type === 'text'"
              >
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col" class="py-1 bg-light"></th>
                      <th scope="col" class="py-1 bg-light">Sphere (SPH)</th>
                      <th scope="col" class="py-1 bg-light">Cylinder (CYL)</th>
                      <th scope="col" class="py-1 bg-light">Axis</th>
                      <th scope="col" class="py-1 bg-light">Add</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">(OD) Right eye</th>
                      <td>{{ item.prescription.rightSph }}</td>
                      <td>{{ item.prescription.rightCyl }}</td>
                      <td>{{ item.prescription.rightAxis }}</td>
                      <td>
                        {{ item.prescription.rightAdditional }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">(OS) Left eye</th>
                      <td>{{ item.prescription.leftSph }}</td>
                      <td>{{ item.prescription.leftCyl }}</td>
                      <td>{{ item.prescription.leftAxis }}</td>
                      <td>
                        {{ item.prescription.leftAdditional }}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div class="font-weight-bold pb-1">PD (Pupillary Distance):</div>
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col" class="py-1 bg-light">Left PD</th>
                      <th scope="col" class="py-1 bg-light">Right PD</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{ item.prescription.rightPd }}</td>
                      <td>{{ item.prescription.leftPd }}</td>
                    </tr>
                  </tbody>
                </table>
                <div class="font-weight-bold pb-1">Prism Value</div>
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col" class="py-1 bg-light"></th>
                      <th scope="col" class="py-1 bg-light">Vertical</th>
                      <th scope="col" class="py-1 bg-light">Base Direction</th>
                      <th scope="col" class="py-1 bg-light">Horizontal</th>
                      <th scope="col" class="py-1 bg-light">Base Direction</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">(OD) Right eye</th>
                      <td>
                        {{ item.prescription.prismVerticalRight }}
                      </td>
                      <td>
                        {{ item.prescription.prismVerticalBaseRight }}
                      </td>
                      <td>
                        {{ item.prescription.prismHorizontalRight }}
                      </td>
                      <td>
                        {{ item.prescription.prismHorizontalBaseRight }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">(OS) Left eye</th>
                      <td>
                        {{ item.prescription.prismVerticalLeft }}
                      </td>
                      <td>
                        {{ item.prescription.prismVerticalBaseLeft }}
                      </td>
                      <td>
                        {{ item.prescription.prismHorizontalLeft }}
                      </td>
                      <td>
                        {{ item.prescription.prismHorizontalBaseLeft }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-collapse>
              <b-collapse
                :id="`prescription-${item.id}`"
                v-else-if="item.prescription_type === 'file'"
              >
                <img :src="imagePath+item.prescription.file" class="img-fluid" />
              </b-collapse>
              <hr class="my-1" />
              <div class="d-flex justify-content-between">
                <div class="font-weight-bold p-0 m-0">Lens Package</div>
                <div class="font-weight-bold text-black p-0 m-0">
                  {{ item.lense_package_name }}
                </div>
              </div>
              <hr class="my-1" />
              <div class="d-flex justify-content-between">
                <div class="font-weight-bold p-0 m-0">Lens Type</div>
                <div class="font-weight-bold text-black p-0 m-0">
                  {{ item.lense_type_name }}
                </div>
              </div>
              <div class="d-flex justify-content-between pt-3">
                <div class="d-flex">
                  <div class="pr-2">Total</div>
                  <!-- <div class="font-weight-bolder text-black">Our Brand: Cofee Brown</div> -->
                </div>
                <h5 class="font-weight-bolder">$ {{ item.total }}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-300px">
        <div class="shadow rounded-sm shadow bg-white p-4 mb-2">
          <div class="font-weight-bolder text-black pb-3">Shipping Address</div>
          <div class="pb-1">
            {{ order.shippingAddress.name }}, {{ order.shippingAddress.address }},
            {{ order.shippingAddress.street_name }}<br />
            Zip: {{ order.shippingAddress.zipcode }}<br />
            Phone: {{ order.shippingAddress.phone }}<br />
            Phone 2: {{ order.shippingAddress.phone2 }}<br />
            Mail: {{ order.shippingAddress.email }}<br />
          </div>
          <hr />
          <div class="font-weight-bolder text-black pb-3">Billing Address</div>
          <div class="pb-1">
            {{ order.billingAddress.name }}, {{ order.billingAddress.address }},
            {{ order.billingAddress.street_name }}<br />
            Zip: {{ order.billingAddress.zipcode }}<br />
            Phone: {{ order.billingAddress.phone }}<br />
            Phone 2: {{ order.billingAddress.phone2 }}<br />
            Mail: {{ order.billingAddress.email }}<br />
          </div>
          <hr />
          <div class="font-weight-bolder text-black pb-3">Payment Method</div>
          <div class="pb-1">{{ order.payment_method }}</div>
          <hr />
          <div class="font-weight-bolder text-black pb-3">Payment Status</div>
          <div class="pb-1">{{ order.payment_status }}</div>
          <hr />
          <div class="font-weight-bolder text-black pb-3">Order Status</div>
          <div class="pb-1 text-success" >{{ order.orderStatusInfo.order_status_name }}</div>
          <hr />
        

          <div class="d-flex justify-content-between pb-3 h3">
            <div class="font-weight-bolder text-black pb-1">Grand Total</div>
            <div class="font-weight-bolder">$ {{ order.subTotal.value }}</div>
          </div>
          
          <div class="d-flex justify-content-between pb-3" v-if="order.shippingCost != undefined">
            <div class="text-black">{{ order.shippingCost.title }}</div>
            <h5 class="font-weight-bolder">$ {{ order.shippingCost.value }}</h5>
          </div>

          <div class="d-flex justify-content-between pb-3" v-if="order.discount != undefined">
            <div class="text-black">{{ order.discount.title }}</div>
            <h5 class="font-weight-bolder">$ {{ order.discount.value }}</h5>
          </div>

          <div class="d-flex justify-content-between pb-3" v-if="order.tax != undefined">
            <div class="text-black">{{ order.tax.title }}</div>
            <h5 class="font-weight-bolder">$ {{ order.tax.value }}</h5>
          </div>

          <div class="d-flex justify-content-between pb-3" v-if="order.wallet_discount != undefined">
            <div class="text-black">{{ order.wallet_discount.title }}</div>
            <h5 class="font-weight-bolder">-$ {{ order.wallet_discount.value }}</h5>
          </div>
          
          <hr />
          <div class="d-flex justify-content-between pb-3 h2">
            <div class="font-weight-bolder text-black pb-1">Total</div>
            <div class="font-weight-bolder">$ {{ order.grandTotal.value }}</div>
          </div>
        </div>
        <!-- <b-button block pill class="bg-white bg-hover-primary mb-2"
          >Start Processing</b-button
        >
        <b-button variant="primary" block pill class="mb-2"
          ><i class="las la-check mr-2"></i> Processing</b-button
        >
        <hr class="my-1" />
        <b-button block pill class="bg-white bg-hover-primary mb-2">Shipped</b-button>
        <b-button variant="primary" block pill class="mb-2"
          ><i class="las la-check mr-2"></i> Shipped</b-button
        >
        <hr class="my-1" />
        <b-button block pill class="bg-white bg-hover-primary mb-2"
          >Generate Invoice</b-button
        >
        <b-button variant="primary" block pill class="mb-2"
          ><i class="las la-check mr-2"></i> Invoice Generated</b-button
        > -->
        <hr class="my-1" />
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import OrderService from "@/core/services/api/order";
export default {
  components: {},
  data() {
    return {
      id: this.$route.params.id ? parseInt(this.$route.params.id) : null,
      orderResponse: null,
      imagePath:process.env.VUE_APP_API_STORAGE_URL
    };
  },
  mounted() {
    this.getOrder();
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Dashboard",
        route: "/dashboard",
      },
      {
        title: "Orders",
        route: "/order",
      },
      {
        title: "Order Detail",
      },
    ]);
  },
  computed: {
    order() {
      var e = this.orderResponse;
      var order = {};
      if (e != null) {
        order = {
          id: e.id,
          date: new Date(e.created_at).toDateString(),
          invoice_prefix: e.invoice_prefix,
          invoice_no: e.invoice_no,
          payment_method: e.payment_method,
          payment_status: e.payment_status,
          subTotal: e.orderTotalInfo.find((element) => element.code === "subtotal"),
          grandTotal: e.orderTotalInfo.find((element) => element.code === "grandTotal"),
          tax: e.orderTotalInfo.find((element) => element.code === "tax"),
          wallet_discount: e.orderTotalInfo.find((element) => element.code === "wallet_discount"),
          shippingCost: e.orderTotalInfo.find(
            (element) => element.code === "shipping_charge"
          ),
          discount: e.orderTotalInfo.find(
            (element) => element.code === "coupon_discount"
          ),
          orderStatusInfo: e.orderStatusInfo.find((element) => element.order_status_id === e.order_status),
          billingAddress: {
            name: e.billing_address_name,
            email: e.billing_address_email,
            phone: e.billing_address_phone,
            phone2: e.billing_address_phone2,
            street: e.billing_address_street_name,
            address: e.billing_address_address,
            zipcode: e.billing_address_zipcode,
          },
          shippingAddress: {
            name: e.shipping_address_name,
            email: e.shipping_address_email,
            phone: e.shipping_address_phone,
            phone2: e.shipping_address_phone2,
            street: e.shipping_address_street_name,
            address: e.shipping_address_address,
            zipcode: e.shipping_address_zipcode,
          },
          products: [],
        };
        e.orderProduct.map((p) => {
          const product = {
            id: p.order_product_id,
            name: p.name,
            model: p.model,
            prescription_type: p.priscription_type,
            prescription:
              p.priscription !== "{}" && p.priscription !== null && p.priscription !== ""
                ? JSON.parse(p.priscription)
                : false,
            glass_usage_id: p.glass_usage_id,
            glass_usage_amount: p.glass_usage_amount,
            lense_package_id: p.lense_package_id,
            lense_package_amount: p.lense_package_amount,
            lense_type: p.lense_type,
            lense_type_amount: p.lense_type_amount,
            product_id: p.product_id,
            quantity: p.quantity,
            price: p.price,
            total: p.total,
            glass_usage_name: p.glass_usage_name,
            lense_package_name: p.lense_package_name,
            lense_type_name: p.lense_type_name,
            image: p.images.find((element) => element.image_type === "default")
          };
          return order.products.push(product);
        });
      } 
      return order;
    },
  },
  methods: {
    async getOrder() {
      this.orderResponse = await OrderService.getOrderById(this.id);
    },
  },
  watch: {

  },
};
</script>

<style lang="scss"></style>
